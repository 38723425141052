const initialState = {
  isCloud: "",
};

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_isCloud':
      return { ...state, isCloud: action.payload };

    default:
      return state;
  }
};

export default statusReducer;
