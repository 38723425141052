// GeneralTab.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Form, Row, Col,Select,Button } from 'antd';
import OffsetField from '../SettingsAQM05/components/OffsetField';

const GeneralTab = (props) => {
  const {Option} = Select
  const generalState = useSelector((state) => state.lorwan);
  const dispatch = useDispatch();
  const min= generateArray()
function generateArray() {
  let arr = [];
  for (let i = 1; i <= 1440; i++) {
    arr.push(i.toString().padStart(4, '0'));
  }
  return arr;
}
  // const handleFieldChange = (field, value) => {
  //   dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  // };
const handleFieldChange = (field, value, type, length) => {
  if (field === "region") {
    dispatch({ type: `UPDATE_LORWAN_rx2DataRate`, payload: "" });
    dispatch({ type: `UPDATE_LORWAN_rxChannel`, payload: "" });
  }

  let hex = new RegExp(`^[0-9A-Fa-f]{1,${length}}$`); // Dynamic length for hex
  let no = new RegExp(`^\\d{1,${length}}$`);  // Dynamic length for numbers
  let noString = new RegExp(`^-?\\d{1,${length}}$`);  // Dynamic length for noString
  
  if ((type === "hex" && hex.test(value) && value.length <= length) || (value.length==0) ) {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  } else if ((type === "number" && no.test(value) && value.toString().length <= length )|| (value.length==0)) {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  } else if ((type === "noString" && noString.test(value) && value.length <= length) || (value.length==0)) {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  }
  
};


   const handleIncrement = (field,val,type,length) => {
    let inc= 1;
    if(field=="offsetCo2"){
      inc=5
    }
    let finalValue='';
    let newVal= Number(parseFloat(val) + inc);
    let sign = newVal >= 0?"+":"-";
    let lesserLength = length - (Math.abs(newVal).toString().length +1);
    if(lesserLength==4){
      newVal= `${sign}0000${Math.abs(newVal)}`;
    }else if(lesserLength==3){
      newVal= `${sign}000${Math.abs(newVal)}`;
    }else if(lesserLength==2){
      newVal= `${sign}00${Math.abs(newVal)}`;
    }else if(lesserLength==1){
      newVal= `${sign}0${Math.abs(newVal)}`;
    }else{
      newVal = `${sign}${Math.abs(newVal)}`;
    }
    
    console.log("newVal",newVal,length,lesserLength,newVal.length +1)

    
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: newVal });
  };

  const handleDecrement = (field,val,type,length) => {
    let inc= 1;
    if(field=="offsetCo2"){
      inc=5
    }
    let finalValue='';
    let newVal= Number(parseFloat(val) - inc);
    let sign = newVal >= 0?"+":"-";
    let lesserLength = length - (Math.abs(newVal).toString().length +1);
    if(lesserLength==4){
      newVal= `${sign}0000${Math.abs(newVal)}`;
    }else if(lesserLength==3){
      newVal= `${sign}000${Math.abs(newVal)}`;
    }else if(lesserLength==2){
      newVal= `${sign}00${Math.abs(newVal)}`;
    }else if(lesserLength==1){
      newVal= `${sign}0${Math.abs(newVal)}`;
    }else{
      newVal = `${sign}${Math.abs(newVal)}`;
    }

        dispatch({ type: `UPDATE_LORWAN_${field}`, payload:newVal});
  };



  return (
    <Form>
      <Row gutter={16} className=''>

        <Col span={24}>

          <Form.Item label="Reporting Interval" className='settingfield'>
            <Select
              value={generalState.reportingInterval}
              onChange={(e) => handleFieldChange('reportingInterval', e,'number',4)}
              status={props.error && props.error['reportingInterval']?"error":""}
              disabled={props.mode && props.mode=="disabled"?true:false}
              showSearch
            >
              {min.map(el=>{
               return <Option value={el} key={"minutes"+el}>{parseInt(el)+" min"}</Option>
              })}
            </Select>

          </Form.Item>
        </Col>


         <OffsetField
          label="Temperature (°C)"
          field="offsetTemp"
          resolution={0.1}
          range={[-99, 99]}
          format={'+00.0'}
          charLength={3}
          mode={props.mode && props.mode=="disabled"?true:false}
          error={props.error}
      />
                <OffsetField
        label="Offset Humidity (%Rh)"
        field="offsetHumidity"
        resolution={1}
        range={[-99, 99]}
        format={'+00'}
        charLength={3}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="Offset C02 (PPM)"
        field="offsetCo2"
        resolution={5}
        range={[-9999, 9999]} // Assuming CO2 ranges to higher values
        charLength={5}
        format={'+9999'}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />


        {/* <Col span={24}>

    
          <Form.Item className='settingfield' label="Offset Temperature (°C)">

      <Row gutter={16}>
          <Col span={6}>
            <Button disabled={props.mode && props.mode=="disabled"?true:false} onClick={()=>handleDecrement('offsetTemp',generalState.offsetTemp,'noString',3)} className='btnChannel'>-</Button>
          </Col>

          <Col span={12}>
            <Input
              value={generalState.offsetTemp}
              className='textCenter'
              status={props.error && props.error['offsetTemp']?"error":""}
              onChange={(e) => handleFieldChange('offsetTemp', e.target.value,'noString',3)}
              disabled={props.mode && props.mode=="disabled"?true:false}
            />
          </Col>
                <Col span={6}> 
            <Button disabled={props.mode && props.mode=="disabled"?true:false} onClick={()=>handleIncrement('offsetTemp',generalState.offsetTemp,'noString',3)} className='btnChannel'>+</Button>
          </Col>

          </Row>
    </Form.Item>

        </Col> */}
        <Col span={24}>
          {/* <Form.Item label="Offset  Humidity" className='settingfield'>
            <Input
              type="text"
              placeholder='+5'
              value={generalState.offsetHumidity}
              onChange={(e) => handleFieldChange('offsetHumidity', e.target.value,'numberWithString',3)}
            />
          </Form.Item> */}


          {/* <Form.Item className='settingfield' label="Offset Humidity (%Rh)">

      <Row gutter={16}>
          <Col span={6}>
            <Button disabled={props.mode && props.mode=="disabled"?true:false} onClick={()=>handleDecrement('offsetHumidity',generalState.offsetHumidity,'noString',3)} className='btnChannel'>-</Button>
          </Col>

          <Col span={12}>
            <Input
              value={generalState.offsetHumidity}
              className='textCenter'
                            status={props.error && props.error['offsetHumidity']?"error":""}
              onChange={(e) => handleFieldChange('offsetHumidity', e.target.value,'noString',3)}
              disabled={props.mode && props.mode=="disabled"?true:false}
            />
          </Col>
                <Col span={6}> 
            <Button disabled={props.mode && props.mode=="disabled"?true:false} onClick={()=>handleIncrement('offsetHumidity',generalState.offsetHumidity,'noString',3)} className='btnChannel'>+</Button>
          </Col>

          </Row>
    </Form.Item> */}



        </Col>
        <Col span={24}>
          {/* <Form.Item label="Offset Co2" className='settingfield'>
            <Input
              type="text"
              value={generalState.offsetCo2}
              placeholder='+200'
              onChange={(e) => handleFieldChange('offsetCo2', e.target.value,'numberWithString',5)}
            />
          </Form.Item> */}


{/* <Form.Item className='settingfield' label="Offset C02 (PPM)">

      <Row gutter={16}>
          <Col span={6}>
            <Button disabled={props.mode && props.mode=="disabled"?true:false} onClick={()=>handleDecrement('offsetCo2',generalState.offsetCo2,'noString',5)} className='btnChannel'>-</Button>
          </Col>

          <Col span={12}>
            <Input
              value={generalState.offsetCo2}
              className='textCenter'
                            status={props.error && props.error['offsetCo2']?"error":""}
              onChange={(e) => handleFieldChange('offsetCo2', e.target.value,'noString',5)}
              disabled={props.mode && props.mode=="disabled"?true:false}
            />
          </Col>
                <Col span={6}> 
            <Button disabled={props.mode && props.mode=="disabled"?true:false} onClick={()=>handleIncrement('offsetCo2',generalState.offsetCo2,'noString',5)} className='btnChannel'>+</Button>
          </Col>

          </Row>
    </Form.Item> */}



        </Col>
      </Row>


    </Form>
  );
};

export default GeneralTab;
