import Scan from './Scan';
import WriteLanding from './Writer/index';
import { useState, useEffect } from 'react';
import { ActionsContext } from '../../contexts/context';
import AbStractNFC from '../../assets/images/absract.png';
import { useNavigate } from 'react-router-dom';
import StatusLanding from './Status';
import { useSelector, useDispatch } from 'react-redux';
import StatusLanding05 from './StatusAQM05';

function ScanLanding() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generalState = useSelector((state) => state.lorwan);

  const [actions, setActions] = useState(null);
  const { scan, write } = actions || {};
  const actionsValue = { actions, setActions };

  const onHandleAction = (actions) => {
    setActions({ ...actions });
    // Send message to React Native WebView for scanning
    if (actions.scan) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'startScan' }));
    }
  };



  useEffect(() => {
    dispatch({ type: 'UPDATE_LORWAN_deviceEui', payload: "" });
    dispatch({ type: `UPDATE_SCAN_deviceEui`, payload: "" });
    dispatch({ type: `UPDATE_RESET_deviceEui`, payload: "" });
    localStorage.removeItem('nfcdeviceEui');
    dispatch({ type: 'UPDATE_SCAN_status', payload: false });
    dispatch({ type: 'UPDATE_LORWAN_status', payload: false });
    dispatch({ type: 'UPDATE_isCloud', payload: '' })
    setActions({
      scan: null,
      write: null
    });
  }, []);

  return (
    <div className="App">
      {generalState.status ?generalState.model_number ==="AQM05"? <StatusLanding05/>:<StatusLanding /> :

        <div className='container'>
          <div className='imageContainer' style={{margin:"90px auto 50px"}}>
            <img src={AbStractNFC} className='imageGrayScale' />
          </div>
          <h4 className='titleFont'>Tap your NFC enabled phone to the SafeAir Device</h4>
          <div className="App-container-bottom">
            <button onClick={() => onHandleAction({ scan: 'scanning', write: null })} className="btn btn-transparent">Scan Now</button>
          </div>
          <ActionsContext.Provider value={actionsValue}>
            {scan && <Scan />}
            {write && <WriteLanding />}
          </ActionsContext.Provider>
        </div>}
    </div>
  );
}

export default ScanLanding;
