import { createStore, combineReducers } from 'redux';
import lorwanReducer from './reducers/lorwanReducer';
import scanReducer from './reducers/scanReducer';
import resetReducer from './reducers/resetReducer';
import statusReducer from './reducers/statusReducer';
// import xyzReducer from './reducers/xyzReducer';

const rootReducer = combineReducers({
  lorwan: lorwanReducer,
  scan: scanReducer,
  reset: resetReducer,
  status:statusReducer
//   xyz: xyzReducer,
});

const store = createStore(rootReducer);

export default store;